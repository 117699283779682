<template>
  <div>
    <v-row>
      <v-col lg="12">
       <activity-area :isAdmin="true"  :height="200"> 
      </activity-area>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActivityArea from './ActivityArea.vue';

export default {
  components: {        
    ActivityArea
    },

   data() {
    return {
      parentID: 0,
      trnID:0, 
      trnState:0,     
    };
  },
  methods: {
 
  },
};
</script>
