<template>
	<s-crud
		@returnObject="refresh($event)"
		no-border
		title="Actividades por Area"
		:config="config"
		@save="save($event)"
		edit
		add
		@clearForm="clearForm()"
		@rowSelected="rowSelected($event)"
		remove
		:filter="filter"
		search-input
		ref="crud"
		no-full
	>
		<template v-slot:filter>
			<v-col>
				<v-row justify="center">

					<v-col cols="4" lg="3">
						<s-select-definition
							label="Nave"
							:def="1342"
							v-model="filter.TypeShip"
							@input="inputShip($event)"
						></s-select-definition>
					</v-col>

					<v-col cols="8" lg="5">
						<s-select-definition
							
							v-model="filter.TypeArea"
							:def="def"
							:dgr="dgr"
							label="Area"
						>
						</s-select-definition>
					</v-col>
				</v-row>
			</v-col>
		</template>

		<template scope="props">
			<!--  <v-container class="pb-0"> -->
			<v-container>
				<v-row justify="center">
					

					<v-col cols="12" lg="2" md="4" sm="12" class="s-col-form" >
						<s-select-definition
							label="Nave"
							:def="1342"
							v-model="props.item.TypeShip"
							@input="inputShip($event)"
						></s-select-definition>
					</v-col>

					<v-col
						cols="12"
						class="s-col-form"
						lg="4"
						justfy="center"
						style="margin: initial;"
					>
						<s-select-definition
							v-model="props.item.TypeArea"
							label="Area"
							:def="def"
							:dgr="dgr"
						></s-select-definition>
					</v-col>

					<v-col
						cols="12"
						class="s-col-form"
						justfy="center"
						style="margin: initial;"
						lg="4"
					>
						<s-text
							v-model="props.item.AtaDescription"
							label="Descripción"
						>
						</s-text>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<template v-slot:AtaStatus="{ row }">
			<v-chip
				style="margin:0px"
				x-small
				:color="row.AtaStatus == 1 ? 'success' : 'error'"
			>
				{{ row.AtaStatus == 1 ? "Activa" : "Inactiva" }}
			</v-chip>
		</template>

		<template v-slot:Estado="{ row }">
			<v-chip
				style="margin:0px"
				x-small
				:color="row.Estado == 'Finalizado' ? 'error' : 'success'"
			>
				{{ row.Estado }}
			</v-chip>
		</template>
	</s-crud>
</template>


<script>
	import _service from "@/services/FreshProduction/PrfActivityAreaService";
	export default {
		name: "RequirementServiceActivityArea",
		components: {},
		props: {
			typeArea: { type: Number, default: 0 },
			parentID: { type: Number, default: 0 },
			addNoFunction: { type: Boolean, default: false }
		},

		data() {
			return {
				trnID: 0,
				dialogEdit: false,
				ataID: 0,
				itemsSelect: [],
				itemsFarm: [],
				filter: { TypeArea: 0 },
				dgr: 40,
				def: 1281,
			};
		},

		computed: {
			config() {
				return {
					model: {
						//RETORNAR PROPIEDAD COMPUTADA
						AtaID: "ID",
						AtaDescription: "string",
						DedDescription: "string",
						Estado: "string",
						AtaStatus: "status"
					},
					service: _service,
					headers: [
								
								{ text: "ID", value: "AtaID", sortable: false },
								
								{
									text: "Nave",
									value: "TypeShipName",
									sortable: false
								},
								{
									text: "Area",
									value: "DedDescription",
									sortable: false
								},
								{
									text: "Actividad",
									value: "AtaDescription",
									sortable: false
								},
								{
									text: "Estado",
									value: "AtaStatus",
									sortable: false
								}
						  ]
				};
			},

			isAdmin() {
				this.$fun.getSecurity().IsLevelAdmin;
			}
		},

		methods: {

			inputShip(item){
				
					if(item == 1){
						this.def = 1281
						this.dgr = 40
					}

					if(item == 2){
						this.def = 1348
						this.dgr=41
						
					}
	
			},
			Initialize() {
				//DedID: this.DedID -- base de datos: campo actual
				this.filter = { TypeArea: this.typeArea };
			},

			edit(item) {
				this.dialogEdit = true;
			},

			rowSelected(rows) {
				if (rows.length > 0) {
					this.ataID = rows[0].AtaID;
					this.$emit("rowSelected", rows[0]);
					this.itemsSelect = rows[0];
				}

				console.log(this.itemsSelect);
			},

			save(item) {
				if (item.AtaDescription.length == 0) {
					this.$fun.alert(
						"Debe ingresar una descripcion para la actividad",
						"warning"
					);
					return;
				}

				item.SecStatus = 1;
				item.AtaStatus = 1;
				item.save();
			}
		},
		watch: {
			// este DedID es el que esta en variety, vista general en DedID  ="dedID"
			typeArea() {
				this.Initialize();
			},
			
		},
		created() {
			this.filter = { TypeArea: 0 };
		}
	};
</script>
