import Service from "../Service";
import Vue from "vue";
const resource = "activityArea/"

export default {
    save(prflin, requestID) {
        return Service.post(resource + "save", prflin, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(RequestID, area) {
        return Service.post(resource + "list", {}, {
            params: { RequestID: RequestID, typearea: area }
        });
    },
    GetUrllist() {
        return Vue.prototype.$http.defaults.baseURL + "/activityArea/list";
    },

};